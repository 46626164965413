import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import IndexPage from './components/Index.js';
import AuthPage from './components/Auth.js';
import DashboardPage from './components/Dashboard.js';
import CookiePage from './components/Cookie.js';
import _404Page from './components/404.js';
import PricingPage from './components/Pricing.js';
import { UserContextProvider } from './providers/UserProvider.js';
import { OpenAiProvider } from './providers/OpenAiProvider.js';
import TestChatbot from './components/TestChatbot.js';
import Navbar from './components/Navbar.js';
import HelpChatbotProvider from './providers/HelpChatbotProvider.js';
import Profile from './components/Profile.js';
import Automations from './components/Automations.js';
import Bookings from './components/Bookings.js';
import TermsPrivacyPage from './components/TermsandPrivacy.js';
import Help from './components/Help.js';
import TrainingManager from './components/TrainingManager.js';

function App() {
  return (
    <div className='App'>
      <UserContextProvider>
        <OpenAiProvider>
          <HelpChatbotProvider>
            <Navbar />
            <Routes>
              <Route path='/dashboard' element={<DashboardPage />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/automations' element={<Automations />}>
                <Route path='/automations/:id' element={<Automations />} />
              </Route>
              <Route path='/chatbot'>
                <Route path='/chatbot/:id' element={<TestChatbot />} />
              </Route>
              <Route path='/help' element={<Help />} />
              <Route path='/account' element={<PricingPage />} />
              <Route path='/checkout' element={<PricingPage />} />
              <Route path='/cookie' element={<CookiePage />} />
              <Route path='/training/:id' element={<TrainingManager />} />
            </Routes>
          </HelpChatbotProvider>
        </OpenAiProvider>
      </UserContextProvider>
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='/auth' element={<AuthPage />} />
        <Route path='/demo' element={<Bookings />} />
        <Route path='/pricing' element={<PricingPage />} />
        <Route path='/terms-privacy' element={<TermsPrivacyPage />} />
        {/* <Route path='/*' element={<_404Page />} /> */}
      </Routes>
      <div className='footer text-white bg-gray-900 text-center w-full p-2'>
        <p>© {new Date().getFullYear()} Choony.io</p>
        <a href='/terms-privacy'>Terms & Privacy</a> |{' '}
        <a href='https://www.choony.io/'>Choony.io</a>
      </div>
    </div>
  );
}

export default App;
