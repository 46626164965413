import React, { useState, useEffect, useRef } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import axios from 'axios';
import { useUser } from '../providers/UserProvider';

const EditChatbotForm = ({ assistant }) => {
  const MAXINSTRUCTIONCHARS = 32768;
  const [chatbot, setChatbot] = useState(null);
  const [instructuctionChars, setInstructionChars] = useState(0);
  const [loading, setLoading] = useState(true);
  const { model, instructions } = chatbot || {};
  const [chatbotLogo, setChatbotLogo] = useState(null);

  const chatbot_name = assistant?.name || '';
  const current_assistant = assistant?.assistant_id || '';
  const {
    result: { userId },
  } = useUser();
  const { models, getModels } = useOpenAi();
  const [originalData, setOriginalData] = useState(null);
  const focusRef = useRef(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const resourcePath = `${process.env.REACT_APP_URL}/api/openai/assistant/${userId}/${current_assistant}`;
  const settingsPath = `${process.env.REACT_APP_URL}/api/settings/${current_assistant}/load`;

  // Ensure models are loaded
  useEffect(() => {
    if (userId && (!models || models.length === 0)) {
      getModels(userId);
    }
  }, [userId, models, getModels]);

  // Load chatbot settings to get the logo
  useEffect(() => {
    const loadChatbotSettings = async () => {
      if (!current_assistant) return;
      
      try {
        const authToken = localStorage.getItem('psg_auth_token');
        const response = await axios.get(settingsPath, {
          headers: {
            'Authorization': authToken ? `Bearer ${authToken}` : undefined
          },
          withCredentials: true
        });
        
        if (response.data && response.data.s3LogoUrl) {
          setChatbotLogo(response.data.s3LogoUrl);
        } else if (response.data && response.data.s3LauncherIcon) {
          // Fallback to launcher icon if logo not available
          setChatbotLogo(response.data.s3LauncherIcon);
        } else {
          // Default fallback
          setChatbotLogo(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
        }
      } catch (error) {
        console.error('Failed to load chatbot settings:', error);
        // Set default logo
        setChatbotLogo(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
      }
    };
    
    loadChatbotSettings();
  }, [current_assistant]);

  // Focus input when component loads
  useEffect(() => {
    if (!focusRef.current) return;
    setTimeout(() => {
      focusRef.current?.focus();
    }, 1000);
  }, [focusRef]);

  // Load chatbot data
  useEffect(() => {
    const loadChatbotData = async () => {
      if (!userId || !current_assistant) {
        console.log("Missing userId or assistant_id, can't load chatbot data");
        return;
      }
      
      setLoading(true);
      setError(null);
      
      try {
        console.log(`Fetching chatbot data from: ${resourcePath}`);
        const authToken = localStorage.getItem('psg_auth_token');
        const response = await axios.get(resourcePath, {
          headers: {
            'Authorization': authToken ? `Bearer ${authToken}` : undefined
          },
          withCredentials: true
        });
        
        console.log("Chatbot data received:", response.data);
        
        if (!response.data) {
          throw new Error("No data received from server");
        }
        
        setOriginalData(response.data); // Save the original data
        
        // Ensure the model is in the available models list
        if (models.length > 0 && !models.some((m) => m.id === response.data.model)) {
          console.warn(`Model ${response.data.model} not found in available models, using first available model`);
          response.data.model = models[0].id;
          
          // Update the model on the server
          await axios.post(resourcePath, { chatbot: response.data });
        }
        
        setChatbot(response.data); // Set the chatbot data
        setInstructionChars(response.data.instructions?.length || 0);
        focusRef.current?.focus(); // Optionally set focus when data is loaded
      } catch (error) {
        console.error('Failed to fetch chatbot data:', error);
        setError(`Failed to load chatbot data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    
    loadChatbotData();
  }, [resourcePath, models, userId, current_assistant]);

  const onChangeChatbot = (changes) => {
    setMessage(null);
    setChatbot({ ...chatbot, ...changes });
  };

  const onSaveChatbot = async () => {
    setMessage(null);
    setError(null);
    
    try {
      if (!chatbot) {
        throw new Error("No chatbot data to save");
      }
      
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(resourcePath, 
        { chatbot },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken ? `Bearer ${authToken}` : undefined
          },
          withCredentials: true
        }
      );
      
      setOriginalData(response.data); // Update the original data
      setChatbot(response.data); // Update the chatbot data
      setMessage('Chatbot saved successfully');
      focusRef.current?.focus(); // Optionally set focus after saving
    } catch (error) {
      console.error('Failed to save chatbot data:', error);
      setError(`Failed to save chatbot: ${error.message}`);
    }
  };

  const onResetChatbot = () => {
    setChatbot(originalData); // Reset chatbot data to original data
    setInstructionChars(originalData?.instructions?.length || 0);
    setMessage(null);
    setError(null);
  };

  if (loading) {
    return <div className="text-white text-center py-4">Loading chatbot data...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-4">
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="mt-2 px-4 py-2 bg-gray-700 text-white rounded-lg"
        >
          Reload Page
        </button>
      </div>
    );
  }

  if (!chatbot) {
    return <div className="text-white text-center py-4">No chatbot data available</div>;
  }

  return (
    <section tabIndex={0}>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Chatbot Name
        </label>
        <div className="flex items-center">
          {chatbotLogo && (
            <div className="flex-shrink-0 mr-3">
              <img 
                src={chatbotLogo} 
                alt="Chatbot logo" 
                className="w-10 h-10 rounded-full object-cover border border-gray-700"
              />
            </div>
          )}
          <input
            className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
            type='text'
            value={chatbot_name}
            readOnly
            placeholder='Chatbot name'
            tabIndex={0}
          />
        </div>
      </div>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Model
        </label>
        <select
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='select'
          value={model || ''}
          tabIndex={0}
          onChange={(e) => onChangeChatbot({ model: e.target.value })}
        >
          {models.length === 0 ? (
            <option value="">Loading models...</option>
          ) : (
            models.map((m) => (
              <option key={m.id} value={m.id}>
                {m.description || m.id}
              </option>
            ))
          )}
        </select>
      </div>
      <div className='mb-8'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Edit or Update Instructions
        </label>
        <textarea
          className='block py-2 px-4 w-full mb-1 h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
          placeholder='Enter your instructions'
          value={instructions || ''}
          tabIndex={0}
          ref={focusRef}
          onChange={(e) => {
            onChangeChatbot({ instructions: e.target.value });
            setInstructionChars(e.target.value.length);
          }}
        />
        {instructuctionChars <= MAXINSTRUCTIONCHARS ? (
          <label
            className='block mt-1 text-sm font-medium text-white'
            htmlFor=''
          >
            You have up to {MAXINSTRUCTIONCHARS} characters to use. You have
            used {instructuctionChars} characters.
          </label>
        ) : (
          <label
            className='block mt-1 text-sm font-medium text-white text-red-500'
            htmlFor=''
          >
            You are over the character limit. You have used{' '}
            {instructuctionChars} characters.
          </label>
        )}
      </div>
      {message && <p className='text-green-500 my-2'>{message}</p>}
      {error && <p className='text-red-500 my-2'>{error}</p>}
      <div className='mb-4 flex gap-2'>
        <button
          className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
          onClick={onResetChatbot}
        >
          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
          <span>Reset</span>
        </button>
        <button
          className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
          onClick={onSaveChatbot}
        >
          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
          <span>Submit</span>
        </button>
      </div>
    </section>
  );
};

export default EditChatbotForm;
