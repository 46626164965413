import React, { createContext, useContext, useState, useEffect } from 'react';
import { PassageUser } from '@passageidentity/passage-elements/passage-user';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VerifyEmail from '../components/Verify-email';
import axios from 'axios';
import { useChatbotStore } from '../store';

const UserContext = createContext(null);

const UserContextProvider = ({ children }) => {
  axios.defaults.withCredentials = true;
  const [result, setResult] = useState({
    isLoading: true,
    isAuthorized: false,
    username: '',
  });
  const nonAuthPaths = ['/', '/pricing', '/demo', '/terms-privacy'];
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const setSubscribed = useChatbotStore((state) => state.setSubscribed);
  const setPlan = useChatbotStore((state) => state.setPlan);

  useEffect(() => {
    const getUser = async () => {
      if (!result.userId) return;
      const authToken = localStorage.getItem('psg_auth_token');
      try {
        const user = await axios.get(
          `${process.env.REACT_APP_URL}/api/user/${result.userId}`,
          { headers: {} }
        );
        if (user.status === 401) {
          setResult({
            isLoading: false,
            isAuthorized: false,
            username: '',
          });
          if (!nonAuthPaths.includes(location.pathname)) navigate('/auth');
        } else {
          setResult((prev) => ({
            ...prev,
            subscribed: user.data.subscribed || false,
            customer_id: user.data.customer_id || '',
            plan: user.data.tier || '',
          }));
          setSubscribed(user.data.subscribed);
          setPlan(user.data.tier);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUser();
  }, [result.isLoading, id]);

  useEffect(() => {
    let cancelRequest = false;
    const fetchUserInfo = async () => {
      try {
        const userInfo = await new PassageUser().userInfo();
        if (cancelRequest) return;
        if (userInfo === undefined) {
          setResult({
            isLoading: false,
            isAuthorized: false,
            username: '',
          });
          if (!nonAuthPaths.includes(location.pathname)) navigate('/auth');
        } else {
          setResult({
            isLoading: false,
            isAuthorized: true,
            username: userInfo.email,
            userId: userInfo.id,
            name: userInfo.user_metadata?.name,
            userInfo: userInfo,
            emailVerified: userInfo.email_verified || false,
          });
        }
      } catch (e) {
        console.error(e);
        setResult({
          isLoading: false,
          isAuthorized: false,
          username: '',
        });
        if (!nonAuthPaths.includes(location.pathname)) navigate('/auth');
      }
    };

    if (!nonAuthPaths.includes(location.pathname)) {
      fetchUserInfo();
    } else {
      setResult((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }

    return () => {
      cancelRequest = true;
    };
  }, [location.pathname]);

  const verifyEmail = async () => {
    const authToken = localStorage.getItem('psg_auth_token');
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/api/user/magic_link`,
        {
          email: result.username,
          channel: 'email',
          ttl: 12,
        },
        { headers: {} }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const signOut = async () => {
    const authToken = localStorage.getItem('psg_auth_token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/${result.userId}/signout`,
        { headers: {} }
      );
      localStorage.clear();
      setResult({
        isLoading: false,
        isAuthorized: false,
        username: '',
      });
      navigate('/auth');
    } catch (e) {
      console.error(e);
      navigate('/auth');
    }
  };

  return (
    <UserContext.Provider value={{ result, setResult, signOut }}>
      {result.isLoading === false && result.emailVerified === false ? (
        <VerifyEmail verify={verifyEmail} />
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    console.error('You are trying to use context data outside of its provider');
  }
  return context;
};

export { UserContextProvider, useUser };
