import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../providers/UserProvider';

const ConversationStarters = ({ assistant }) => {
  const [starters, setStarters] = useState([]);
  const [newStarter, setNewStarter] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const {
    result: { userId },
  } = useUser();
  
  // Load starters when component mounts
  useEffect(() => {
    if (assistant?.assistant_id) {
      loadSettings();
    } else {
      setLoading(false);
    }
  }, [assistant?.assistant_id]);

  // Function to load settings and extract starters
  const loadSettings = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log(`Loading settings for assistant ID: ${assistant.assistant_id}`);
      
      // Get the settings from the server
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/settings/${assistant.assistant_id}/load`
      );
      
      console.log("Settings loaded:", response.data);
      
      // If settings exist and have conversation_starters, use them
      if (response.data) {
        // Safely extract conversation_starters
        if (Array.isArray(response.data.conversation_starters)) {
          setStarters(response.data.conversation_starters);
        } else {
          // If no starters exist yet, initialize with empty array
          setStarters([]);
        }
      }
    } catch (err) {
      console.error("Error loading settings:", err);
      setError("Failed to load conversation starters");
    } finally {
      setLoading(false);
    }
  };

  // Function to add a new starter
  const addStarter = () => {
    if (!newStarter.trim()) return;
    
    const updatedStarters = [...starters, newStarter];
    setStarters(updatedStarters);
    setNewStarter('');
    saveStarters(updatedStarters);
  };

  // Function to remove a starter
  const removeStarter = (index) => {
    const updatedStarters = starters.filter((_, i) => i !== index);
    setStarters(updatedStarters);
    saveStarters(updatedStarters);
  };

  // Function to save starters
  const saveStarters = async (updatedStarters) => {
    if (!assistant?.assistant_id) {
      setError("No assistant ID available");
      return;
    }

    try {
      setMessage(null);
      setError(null);
      console.log(`Saving conversation starters for assistant ID: ${assistant.assistant_id}`);
      
      // First, fetch current complete settings
      const settingsResponse = await axios.get(
        `${process.env.REACT_APP_URL}/api/settings/${assistant.assistant_id}/load`
      );
      
      // Get the existing settings object or initialize an empty one if none exists
      const currentSettings = settingsResponse.data || {};
      console.log("Current settings before update:", currentSettings);
      
      // Create a merged settings object - PRESERVE ALL OTHER PROPERTIES
      const updatedSettings = {
        ...currentSettings,
        conversation_starters: updatedStarters
      };
      
      console.log("Updated settings to save:", updatedSettings);
      
      // Save the entire settings object with our updated starters
      await axios.post(
        `${process.env.REACT_APP_URL}/api/settings/${assistant.assistant_id}/save`,
        updatedSettings  // Send the entire object directly, no wrapping
      );
      
      setMessage('Conversation starters saved successfully');
    } catch (err) {
      console.error('Error saving conversation starters:', err);
      setError('Failed to save conversation starters');
    }
  };

  if (loading) {
    return <div className="text-white text-center py-4">Loading conversation starters...</div>;
  }

  return (
    <div>
      <h2 className="text-xl font-bold text-white mb-4">Conversation Starters</h2>
      <p className="text-gray-400 mb-4">
        Add messages that will be shown as quick-start options when users open the chat widget.
      </p>
      
      <div className="mb-4">
        <div className="flex mb-2">
          <input
            type="text"
            value={newStarter}
            onChange={(e) => setNewStarter(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && addStarter()}
            placeholder="Add a conversation starter..."
            className="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
          />
          <button
            onClick={addStarter}
            className="ml-2 px-4 py-2 text-gray-900 bg-yellowGreen-600 rounded-lg"
          >
            Add
          </button>
        </div>
      </div>
      
      <div className="mb-4">
        {starters.length === 0 ? (
          <p className="text-gray-400">No conversation starters yet. Add some to help users get started.</p>
        ) : (
          <ul>
            {starters.map((starter, index) => (
              <li key={index} className="flex items-center justify-between mb-2 bg-gray-900 p-3 rounded-lg">
                <span className="text-white">{starter}</span>
                <button
                  onClick={() => removeStarter(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  ✕ Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      
      {message && <p className="text-green-500 mt-2">{message}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default ConversationStarters; 