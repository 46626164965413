import React, { useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { useChatbotStore } from '../store';
import ChatbotSettings from './ChatbotSettings';
import AllowedUrls from './AllowedUrls';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import LinkButton from './LinkButton';
import  useScrollToTop  from '../hooks/useScrollToTop';
// Then register the languages you need
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('php', php);

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function TestChatbot() {
  const { id } = useParams();
  const current_assistant = useChatbotStore((state) => state.current_assistant);
  const chatbot_name = useChatbotStore((state) => state.current_assistant_name);
  const isSubscribed = useChatbotStore((state) => state.isSubscribed);
  useScrollToTop();
  const {
    result: { userId },
  } = useUser();
  const phpRef = useRef(null);
  const navigate = useNavigate();

  const handleDownload = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/api/files/download`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: phpRef.current.value }),
      }
    );
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `choony_chatbot.zip`); // The default filename for downloading
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleDownloadPlugin = async () => {
    try {
      // Make a POST request to the download endpoint
      const data =`
     <?php
     /*
     Plugin Name: Chat Widget Integration
     Description: Adds a Chat Widget to the site.
     Version: 1.0
     Author: Choony.io
     */
     
     function enqueue_chat_widget_script() {
         // Enqueue the main chat widget script
         wp_enqueue_script(
             'chat-widget-script',
             '${process.env.REACT_APP_URL}/chat/${userId}/loader/${current_assistant}.js?v=' . time(),
             array(),
             null,
             true
         );
     
         // Inline script to initialize the chat widget after it loads
         wp_add_inline_script('chat-widget-script', 'window.onload = function() { if(window.chatWidgetInit) window.chatWidgetInit(); };');
     }
     
     add_action('wp_enqueue_scripts', 'enqueue_chat_widget_script');
                     `;
      const response = await fetch(`${process.env.REACT_APP_URL}/api/files/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: data
        })
      });

      if (!response.ok) {
        throw new Error('Download failed');
      }

      // Get the blob from the response
      const blob = await response.blob();
      
      // Create a download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'choony-chatbot.zip'; // Name of the downloaded file
      
      // Trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading plugin:', error);
      // Optionally add error handling UI here
    }
  };

  useEffect(() => {
    hljs.highlightAll();
  }, []);
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div className="min-h-screen bg-gray-900">
        <div className="container px-4 mx-auto pt-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-white">Brand and preview</h1>
            <button
              onClick={() => navigate('/dashboard')}
              className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200"
            >
              Back to Dashboard
            </button>
          </div>
        </div>
        <section className='py-12 md:py-24 bg-gray-900 min-h-screen'>
          <div className='container px-4 mx-auto'>
            <h1 className='text-4xl font-bold text-white mb-12'>
              Brand Chatbot
            </h1>
            <p className='text-2xl font-bold text-white mb-4'>{chatbot_name}</p>
            <div className='mx-auto text-white'>
              <div className='mb-24 text-white'>
                <ChatbotSettings />
              </div>
             <h1 className='text-2xl font-bold text-white mb-4'>Embed on your website</h1>
              {(current_assistant && userId && isSubscribed) ||
              userId === 'bhyXpOaD1v1ssTSyL3vbfi6R' ? (
                <div className='mb-24 text-white max-w-6xl'>
                  <div className='mb-24 text-white'>
                    <AllowedUrls />
                  </div>
                  <p className='text-2xl font-bold text-white mb-4 pt-8'>
                    EMBED ON WORDPRESS (download plugin and install it on your site){' '}
                    <LinkButton text='Download Plugin' action={handleDownloadPlugin} />
                  </p>
                  <p className='text-2xl font-bold text-white mb-4'>
                    EMBED PHP
                  </p>
                  <pre>
                    <textarea
                      id='chatbot-embed-script-html'
                      className='w-full h-40 bg-gray-800 border border-gray-700 language-php'
                      readOnly
                      placeholder='Chatbot'
                    >{`<script src="${process.env.REACT_APP_URL}/chat/${userId}/loader/${current_assistant}.js?v=<?php echo time(); ?>" async onload="if(window.chatWidgetInit) window.chatWidgetInit();"></script>`}</textarea>
                  </pre>

                  <p className='text-2xl font-bold text-white mb-4'>
                    EMBED REACT
                  </p>
                  <pre>
                    <textarea
                      id='chatbot-embed-script-html'
                      className='w-full h-96 bg-gray-800 border border-gray-700 language-javascript'
                      readOnly
                      placeholder='Chatbot'
                    >{`
import { useEffect } from 'react';

const useChatWidget = (src) => {
  useEffect(() => {
    // Ensure the source URL is provided.
    if (!src) {
      console.error('Chat widget source URL is required.');
      return;
    }

    // Check if the script is already loaded.
    const scriptAlreadyLoaded =
      sessionStorage.getItem('chatWidgetLoaded') === 'true' &&
      document.getElementById('chat-widget-script') !== null;

    // Function to initialize the chat widget.
    const initChatWidget = () => {
      if (typeof window.chatWidgetInit === 'function') {
        console.log('Initializing chat widget...');
        window.chatWidgetInit();
      } else {
        console.error('Failed to initialize chat widget.');
      }
    };

    // Load the script if it's not already loaded.
    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.src = src;
      script.id = 'chat-widget-script';
      script.async = true;
      script.onload = () => {
        sessionStorage.setItem('chatWidgetLoaded', 'true');
        initChatWidget();
      };
      document.body.appendChild(script);
    } else {
      initChatWidget();
    }

    // Cleanup function to remove the widget and any modifications.
    return () => {
      window.removeChatWidget?.();
    };
  }, [src]);
};

export default useChatWidget;


const ChatWidgetLoader = () => {
  const timestamp = Date.now();
  useChatWidget(
    "${process.env.REACT_APP_URL}/chat/${userId}/loader/${current_assistant}.js?v="+timestamp,
  );

  return null;
};
`}</textarea>
                  </pre>


                  
                </div>
              ) : (
                <p className='text-2xl font-bold text-white mb-4'>
                  YOU NEED TO SUBSCRIBE TO EMBED ON YOUR WEBSITE APPLICATION
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
