import React from 'react';

const UploadInput = ({ 
  allowedFiles = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/csv'
  ], 
  onChange, 
  setter 
}) => {
  return (
    <div className='max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl'>
      <div className='md:flex'>
        <div className='w-full p-3'>
          <div className='relative h-48 rounded-lg border-2 border-green-500 bg-gray-800 flex justify-center items-center shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out'>
            <div className='absolute flex flex-col items-center'>
              <img
                alt='File Icon'
                className='mb-3 w-12 h-12'
                src='/file.png'
              />
              <span className='block text-white font-semibold'>
                Drag &amp; drop your file here
              </span>
              <span className='block text-green-400 font-normal mt-1'>
                or click to upload
              </span>
            </div>
            <input
              className='h-full w-full opacity-0 cursor-pointer'
              type='file'
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  console.log('Selected file:', file);
                  if (allowedFiles.includes(file.type)) {
                    console.log('File type is allowed:', file.type);
                    onChange(e, setter);
                  } else {
                    console.error('File type not allowed:', file.type);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadInput;
