import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOpenAi } from '../providers/OpenAiProvider';
axios.defaults.withCredentials = true;

const ApiKeyManager = ({ userId }) => {
  const [apiKey, setApiKey] = useState('');
  const [obfuscatedKey, setObfuscatedKey] = useState(null);
  const [isValidKey, setIsValidKey] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const { getModels } = useOpenAi();

  // URL for the API endpoint (modify as necessary)
  const apiUrl = `${process.env.REACT_APP_URL}/api/user/${userId}/key`;

  // Function to read the API key with retry mechanism
  const readApiKey = async (attempt = 0) => {
    const authToken = localStorage.getItem('psg_auth_token');
    try {
      const response = await axios.get(apiUrl, {
        headers: { Cookie: `psg_auth_token=${authToken}` },
      });
      setObfuscatedKey(response.data.openai_key);
      const isValid = !response.data.openai_key.toLowerCase().includes('laceme'.toLowerCase());
      setIsValidKey(isValid);
      setShowInstructions(!isValid); // Collapse instructions if key is valid
      getModels(userId);
    } catch (error) {
      if (attempt < 2) {
        // Retry up to 3 times (0, 1, 2)
        console.log(`Retry attempt ${attempt + 1}`);
        setTimeout(() => readApiKey(attempt + 1), 1000); // Wait 1 second before retrying
      } else {
        console.error('Error fetching API key after 3 attempts', error);
        setIsValidKey(false);
        setShowInstructions(true);
      }
    }
  };
  // Function to create or update the API key
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!apiKey) return; // Don't submit if the API key is empty
    const method = obfuscatedKey ? 'patch' : 'post'; // Use PATCH if an API key exists, otherwise use POST

    try {
      const response = await axios({
        method,
        url: apiUrl,
        data: { apiKey },
      });
      setObfuscatedKey(response.data.openai_key);
      setIsValidKey(true);
      setShowInstructions(false); // Collapse instructions after successful submission
      getModels(userId);
      setApiKey(''); // Clear input after submission
    } catch (error) {
      console.error('Error updating API key', error);
      setIsValidKey(false);
      setShowInstructions(true);
    }
  };

  // Function to delete the API key
  const deleteApiKey = async () => {
    try {
      await axios.delete(apiUrl);
      setObfuscatedKey(null);
    } catch (error) {
      console.error('Error deleting API key', error);
    }
  };

  useEffect(() => {
    readApiKey(); // Initial call without delay
  }, [userId]);

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-gray-800 p-6 rounded-lg mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">OpenAI API Key Setup</h2>
          <button
            onClick={() => setShowInstructions(!showInstructions)}
            className="text-yellowGreen-500 hover:text-yellowGreen-400 text-sm flex items-center gap-1"
          >
            {showInstructions ? (
              <>
                <span>Hide Instructions</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              </>
            ) : (
              <>
                <span>Show Instructions</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </>
            )}
          </button>
        </div>

        {showInstructions && (
          <div className="text-gray-300 space-y-3 mb-6 animate-fadeIn">
            <p>To get your OpenAI API key:</p>
            <ol className="list-decimal list-inside space-y-2 ml-4">
              <li>Visit <a href="https://platform.openai.com/api-keys" target="_blank" rel="noopener noreferrer" className="text-yellowGreen-500 hover:underline">OpenAI API Keys page</a></li>
              <li>Sign in or create an account</li>
              <li>Click on "Create new secret key"</li>
              <li>Copy your API key (you won't be able to see it again!)</li>
              <li>Paste it below</li>
            </ol>
            <p className="mt-4 text-sm">
              Note: Your API key is stored securely and used only for your chatbot interactions.
            </p>
          </div>
        )}

        <div className="mb-4">
          <div className={`p-3 rounded-lg flex items-center gap-2 ${isValidKey ? 'bg-green-900/30' : 'bg-yellow-900/30'}`}>
            <div className={`w-3 h-3 rounded-full ${isValidKey ? 'bg-green-500' : 'bg-yellow-500'}`}></div>
            <span className="text-white">
              {isValidKey ? 'API Key is active and working' : 'No valid API key detected'}
            </span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="xs:flex items-center gap-3">
            <input
              className="py-3 px-4 h-12 w-full mb-3 xs:mb-0 text-gray-400 placeholder-gray-400 bg-gray-700 border border-gray-600 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500/20"
              type="password"
              name="openai_api_key"
              placeholder="sk-..."
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              autoComplete="new-password"
            />
            <button
              className="xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-6 h-12 font-bold text-gray-900 bg-yellowGreen-500 hover:bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none"
              type="submit"
            >
              <span>Save API Key</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApiKeyManager;
