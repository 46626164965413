import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EditChatbotForm from './EditChatbotForm';
import EditChatbot from './EditChatbot';
import ConversationStarters from './ConversationStarters';
import { useOpenAi } from '../providers/OpenAiProvider';
import { useChatbotStore } from '../store';
import useScrollToTop from '../hooks/useScrollToTop';
import { useUser } from '../providers/UserProvider';

const TrainingManager = () => {
  useScrollToTop();
  const { id } = useParams();
  const navigate = useNavigate();
  const { chatbots, getChatbots } = useOpenAi();
  const [loading, setLoading] = useState(true);
  const addAssistant = useChatbotStore((state) => state.addAssistant);
  
  const {
    result: { userId },
  } = useUser();

  // Function to handle testing the chatbot
  const testBot = (assistant) => {
    addAssistant(assistant.assistant_id, assistant.name);
    navigate(`/chatbot/${assistant.id}`);
  };

  // Fetch chatbots if needed
  useEffect(() => {
    if (userId && (!chatbots || chatbots.length === 0)) {
      getChatbots(userId).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userId, chatbots, getChatbots]);
  
  // Find the assistant by ID
  const assistant = chatbots.find(a => a.id === parseInt(id));

  if (loading) {
    return <div className="py-12 bg-gray-900 min-h-screen text-white text-center">Loading...</div>;
  }

  if (!assistant) {
    return (
      <div className="py-12 bg-gray-900 min-h-screen text-white text-center">
        <p>Loading...</p>
        <button
          onClick={() => navigate('/dashboard')}
          className="mt-4 bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg"
        >
          Back to Dashboard
        </button>
      </div>
    );
  }

  return (
    <section className="py-12 bg-gray-900 min-h-screen">
      <div className="container px-4 mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-white">Training Manager</h1>
          <div className="flex gap-4">
            <button
              onClick={() => navigate('/dashboard')}
              className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200"
            >
              Back to Dashboard
            </button>
            <button
              onClick={() => testBot(assistant)}
              className="bg-yellowGreen-600 hover:bg-yellowGreen-700 text-gray-900 font-bold py-2 px-4 rounded-lg transition duration-200"
            >
             Brand and preview
            </button>
          </div>
        </div>

        <div className="flex flex-wrap -mx-4 mb-8">
          <div className="w-full px-4 mb-4">
            <h1 className="text-3xl font-bold text-white">
              {assistant.name}
            </h1>
          </div>
        </div>

        <div className="flex flex-wrap -mx-4">
          {/* Instructions/Model Form - Full width on mobile, half on desktop */}
          <div className="w-full lg:w-1/2 px-4 mb-8">
            <div className="p-6 bg-gray-800 rounded-lg h-full">
              <h2 className="text-xl font-bold text-white mb-4">Edit Instructions</h2>
              <EditChatbotForm assistant={assistant} />
            </div>
          </div>

          {/* File Management - Full width on mobile, half on desktop */}
          <div className="w-full lg:w-1/2 px-4 mb-8">
            <div className="p-6 bg-gray-800 rounded-lg h-full">
              <h2 className="text-xl font-bold text-white mb-4">Manage Files</h2>
              <EditChatbot assistant={assistant} />
            </div>
          </div>
          
          {/* Conversation Starters - Full width on mobile, half on desktop */}
          <div className="w-full lg:w-1/2 px-4 mb-8">
            <div className="p-6 bg-gray-800 rounded-lg h-full">
              <ConversationStarters assistant={assistant} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrainingManager; 