import React, { useRef } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import LinkButton from './LinkButton';
import { useChatbotStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { Modal } from './UncontrolledModal';
import EditChatbot from './EditChatbot';
import { useUser } from '../providers/UserProvider';
import EditChatbotForm from './EditChatbotForm';
import { ControlledModal } from './ControlledModal copy';

const LoadingSkeleton = () => (
  <tr className="animate-pulse border-b border-gray-700">
    <td className="p-3">
      <div className="flex items-center">
        <div className="flex-shrink-0 w-10 h-10 bg-gray-700 rounded-full"></div>
        <div className="ml-3 w-24 h-4 bg-gray-700 rounded"></div>
      </div>
    </td>
    <td className="p-3">
      <div className="flex flex-col gap-2 items-center">
        <div className="h-10 w-32 bg-gray-700 rounded"></div>
        <div className="h-10 w-32 bg-gray-700 rounded"></div>
      </div>
    </td>
    <td className="p-3">
      <div className="flex justify-center">
        <div className="h-10 w-32 bg-gray-700 rounded"></div>
      </div>
    </td>
    <td className="p-3">
      <div className="flex justify-center">
        <div className="h-10 w-20 bg-gray-700 rounded-full"></div>
      </div>
    </td>
  </tr>
);

const ChatbotsTable = () => {
  const { chatbots, deleteChatbot, getChatbots, isLoading } = useOpenAi();

  const {
    result: { userId },
  } = useUser();
  const navigate = useNavigate();
  const addAssistant = useChatbotStore((state) => state.addAssistant);
  const addUserId = useChatbotStore((state) => state.addUserId);
  const testBot = (path, id, name) => {
    addAssistant(id, name);
    navigate(path);
  };
  const onDelete = async (assistant_id, assistantName) => {
    const confirmMessage = `Are you sure you want to delete the chatbot "${assistantName}"? This action cannot be undone.`;
    
    if (window.confirm(confirmMessage)) {
      await deleteChatbot(userId, assistant_id);
      await getChatbots(userId);
    }
  };
  return (
    <div className='overflow-x-auto mt-16'>
      <table className='w-full text-left mb-2 table-auto'>
        <thead className='bg-gray-800 text-white'>
          <tr>
            <th className='p-3 text-center w-1/4'>Chatbot</th>
            <th className='p-3 text-center w-1/4'>Train, automate your bot</th>
            <th className='p-3 text-center w-1/4'>Brand and preview</th>
            <th className='p-3 text-center w-1/4'>Remove</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
            </>
          ) : chatbots?.length > 0 ? (
            chatbots.map((assistant) => (
              <tr
                className='text-white border-b border-gray-700'
                key={assistant.id}
              >
                <td className='p-3'>
                  <div className='flex items-center'>
                    <div className='flex-shrink-0 w-10 h-10'>
                      <img
                        className='w-full h-full rounded-full'
                        src={
                          assistant?.settings?.s3LauncherIcon ||
                          'https://choony.s3.amazonaws.com/choony.svg'
                        }
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <p className='whitespace-nowrap'>{assistant.name}</p>
                    </div>
                  </div>
                </td>
                <td className='p-3'>
                  <div className='flex flex-col gap-2 items-center justify-center'>
                    <LinkButton
                      action={() => navigate(`/training/${assistant.id}`)}
                      text={'Manage Training'}
                    />
                    <LinkButton
                      action={() =>
                        testBot(
                          `/automations/${assistant.id}`,
                          assistant.assistant_id,
                          assistant.name
                        )
                      }
                      text={'Tools and configuration'}
                    />
                  </div>
                </td>
                <td className='p-3'>
                  <div className='flex justify-center items-center'>
                    <LinkButton
                      action={() =>
                        testBot(
                          `/chatbot/${assistant.id}`,
                          assistant.assistant_id,
                          assistant.name
                        )
                      }
                      text={'Brand and preview'}
                    />
                  </div>
                </td>
                <td className='p-3'>
                  <div className='flex justify-center items-center'>
                    <button
                      onClick={() => onDelete(assistant.assistant_id, assistant.name)}
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-3 rounded-full'
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center py-8 text-gray-400">
                No chatbots found. Create your first chatbot to get started.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ChatbotsTable;
