import { useEffect, useLayoutEffect } from 'react';

const useScrollToTop = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    
    return () => {
      // Cleanup if needed
    };
  }, []);
};

export default useScrollToTop;
