import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';

// Common color name to hex mapping
const colorNameMap = {
  black: '#000000',
  white: '#FFFFFF',
  red: '#FF0000',
  green: '#008000',
  blue: '#0000FF',
  yellow: '#FFFF00',
  purple: '#800080',
  gray: '#808080',
  pink: '#FFC0CB',
  orange: '#FFA500',
  brown: '#A52A2A',
  
  // Add more common colors as needed
};

const ColorPickerInput = ({ label, color, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [inputValue, setInputValue] = useState(color);

  // Update input value when color prop changes
  useEffect(() => {
    setInputValue(color);
  }, [color]);

  const isValidHex = (color) => {
    return /^#[0-9A-Fa-f]{6}$/.test(color);
  };

  const convertToHex = (colorValue) => {
    // Remove spaces and convert to lowercase
    const normalizedColor = colorValue.toLowerCase().trim();

    // If it's already a valid hex, return it
    if (isValidHex(normalizedColor)) {
      return normalizedColor;
    }

    // If it's a color name, convert to hex
    if (colorNameMap[normalizedColor]) {
      return colorNameMap[normalizedColor];
    }

    // If it's a hex without #, add it
    if (/^[0-9A-Fa-f]{6}$/.test(normalizedColor)) {
      return '#' + normalizedColor;
    }

    return null;
  };

  const handleColorChange = (newColor) => {
    setInputValue(newColor);
    onChange(newColor);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    // Convert and validate the color
    const hexColor = convertToHex(newValue);
    if (hexColor) {
      onChange(hexColor);
    }
  };

  const handleInputBlur = () => {
    const hexColor = convertToHex(inputValue);
    if (hexColor) {
      handleColorChange(hexColor);
    } else {
      // Reset to last valid color if invalid
      setInputValue(color);
    }
  };

  return (
    <div className="mb-6">
      <label className="block mb-1 text-sm font-medium text-white">
        {label}
      </label>
      <div className="flex items-center gap-4">
        <div 
          className="w-12 h-12 rounded cursor-pointer border border-gray-700 flex-shrink-0"
          style={{ backgroundColor: color }}
          onClick={() => setShowPicker(!showPicker)}
        />
        <input
          className="py-2 px-4 h-11 flex-1 text-white placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder="Enter color (hex or name)"
        />
      </div>
      
      {showPicker && (
        <div className="relative mt-2">
          <div className="absolute z-10">
            <div 
              className="fixed inset-0" 
              onClick={() => setShowPicker(false)}
            />
            <div className="relative bg-gray-800 p-2 rounded-lg shadow-lg">
              <HexColorPicker 
                color={color} 
                onChange={handleColorChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPickerInput; 