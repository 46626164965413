import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PoweredBy from './PoweredBy';
import AppStore from './AppStore';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='bg-gray-900 pb-24'>
          <div className='relative'>
            <img
              className='hidden lg:block absolute top-0 right-0 w-full max-w-lg xl:max-w-xl'
              src='aurora-assets/headers/header-dark-right-half-side.png'
              alt=''
            />
            <div className='container px-4 mx-auto'>
              <div className='max-w-sm sm:max-w-lg xl:max-w-2xl mx-auto lg:mx-0 lg:pt-10 lg:pr-10 pb-12 lg:pb-28'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6'>
                  Making custom Chatbots accessible with Zero Code
                </h1>
                <p className='max-w-sm xl:max-w-none text-lg text-white mb-10'>
                  Easily create, instruct and deploy your chatbot anywhere.
                </p>
                <div className='flex flex-col xs:flex-row items-center mb-10'>
                  <a
                    className='group relative mb-4 xs:mb-0 xs:mr-8 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                    href='/dashboard'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Try Choony</span>
                  </a>
                </div>
                <div className='flex flex-col xs:flex-row items-center'>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    15-days free trial
                  </span>
                  <span className='inline-block my-4 xs:my-0 mx-auto xs:mx-4'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3008_28918)'>
                        <mask
                          id='mask0_3008_28918'
                          style={{ maskType: 'luminance' }}
                          maskUnits='userSpaceOnUse'
                          x={0}
                          y={0}
                          width={12}
                          height={12}
                        >
                          <path d='M12 0H0V12H12V0Z' fill='white' />
                        </mask>
                        <g mask='url(#mask0_3008_28918)'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                            fill='#4B6354'
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <img
              className='lg:hidden block w-full pl-8 ml-auto max-w-3xl'
              src='aurora-assets/headers/header-dark-right-half-side.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Dashboard
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/how-it-works/shadow-right-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='xs:max-w-sm md:max-w-sm lg:max-w-4xl mx-auto text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Create your chatbot in 5 minutes
                </h1>
                <p className='text-lg text-gray-400'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='relative flex flex-wrap items-center'>
                <img
                  className='hidden md:block absolute top-0 left-0 mt-7 w-full'
                  src='aurora-assets/how-it-works/line-dark.svg'
                  alt=''
                />
                <div className='relative w-full md:w-1/3 px-4 mb-16 md:mb-0'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                      1
                    </div>
                    <h6 className='text-xl font-bold text-white mb-1'>
                      Add Open API key
                    </h6>
                    <p className='text-sm leading-snug text-gray-400'>
                      Create an account by simply verifying your email and
                      adding an open ai api key. Make sure your key supports
                      GPT-4
                    </p>
                  </div>
                </div>
                <div className='relative w-full md:w-1/3 px-4 mb-16 md:mb-0'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                      2
                    </div>
                    <h6 className='text-xl font-bold text-white mb-1'>
                      Create your chatbot
                    </h6>
                    <p className='text-sm leading-snug text-gray-400'>
                      Add instructions, Brand and style your chatbot
                    </p>
                  </div>
                </div>
                <div className='relative w-full md:w-1/3 px-4'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                      3
                    </div>
                    <h6 className='text-xl font-bold text-white mb-1'>
                      Test &amp; Embed
                    </h6>
                    <p className='text-sm leading-snug text-gray-400'>
                      Go back and edit instructons till your chatbot is perfect
                      for your use case
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                Frequently asked questions
              </h1>
              <p className='text-gray-400'>
                Everything you need to know about the product.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 lg:-mx-12 -mb-16'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    What is Choony?
                  </h6>
                  <p className='text-gray-400'>
                    Choony is a chatbot generator that allows you to train and
                    brand your chatbot easiliy
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How does it work?
                  </h6>
                  <p className='text-gray-400'>
                    You enter a valid open api key and then you can start
                    creating your chatbot.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How much does it cost?
                  </h6>
                  <p className='text-gray-400'>
                    You can find out more about the pricing on the pricing page
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Where are my chatbots saved?
                  </h6>
                  <p className='text-gray-400'>
                    Your chatbots are stored on OPENAI servers and we keep a
                    reference in our database. If you delete the
                    chatbot(assistant) on open ai, then we lose the reference to
                    it.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How do I invite my team?
                  </h6>
                  <p className='text-gray-400'>
                    You can invite your team on the enterprise plan
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Is there a free trial available?
                  </h6>
                  <p className='text-gray-400'>
                    You can create a chatbot for free but in order to embed it
                    on your website, you will need to upgrade to a paid plan
                  </p>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-between gap-6 my-6'>
              <AppStore />
              <PoweredBy />
            </div>
            <div className='mt-16 py-4 px-6 bg-gray-800 rounded-xl'>
              <div className='sm:flex items-center'>
                <div className='xs:flex mb-6 md:mb-0 items-center'>
                  <div className='flex-shrink-0 flex items-center'>
                    <div className='inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <div className='inline-flex items-center justify-center w-14 h-14 bg-teal-700 rounded-full'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 10L12 10L16 10'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M8 14L10 14L12 14'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='-ml-4 inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <img
                        className='block w-14 h-14'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='mt-6 md:mt-0 xs:ml-6'>
                    <h6 className='font-semibold text-white mb-1'>
                      Still have questions?
                    </h6>
                    <p className='text-sm text-gray-400'>
                      Can’t find the answer you’re looking for? Please chat to
                      our team.
                    </p>
                  </div>
                </div>
                <div className='flex-shrink-0 ml-auto sm:pl-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='mr-2'>Get in touch</span>
                        <span className='transform group-hover:translate-x-1 transition duration-200'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.00033 10H15.417M15.417 10L10.417 5M15.417 10L10.417 15'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
