import React, { useState } from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import { useUser } from '../providers/UserProvider';

const CreateChatbot = () => {
  const { models, createChatbot, error, getChatbots } = useOpenAi();
  const MAXINSTRUCTIONCHARS = 32768;
  const [instructuctionChars, setInstructionChars] = useState(0);
  const {
    result: { userId },
  } = useUser();
  const [chatbot, setChatbot] = useState({
    name: '',
    model: '',
    tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
    instructions: '',
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await createChatbot(userId, chatbot);
      await getChatbots(userId);
      setChatbot({
        name: '',
        model: '',
        tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
        instructions: '',
      });
    } catch (error) {
      console.error('Error creating chatbot', error);
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Chatbot Name
        </label>
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='text'
          value={chatbot.name}
          onChange={(e) => setChatbot({ ...chatbot, name: e.target.value })}
          placeholder='Chatbot name'
        />
      </div>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Model
        </label>
        <select
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='select'
          value={chatbot.model}
          onChange={(e) => setChatbot({ ...chatbot, model: e.target.value })}
        >
          <option value=''>Select a model</option>
          {models.map((model) => (
            <option key={model.id} value={model.id}>
              {model.description}
            </option>
          ))}
        </select>
      </div>
      <div className='mb-8'>
        <label className='block mb-1 text-sm font-medium text-white' htmlFor=''>
          Instructions
        </label>
        <textarea
          className='block py-2 px-4 w-full mb-1 h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
          placeholder='Enter your instructions'
          value={chatbot.instructions}
          onChange={(e) => {
            setChatbot({ ...chatbot, instructions: e.target.value });
            setInstructionChars(e.target.value.length);
          }}
        />
        {instructuctionChars <= MAXINSTRUCTIONCHARS ? (
          <label
            className='block mt-1 text-sm font-medium text-white'
            htmlFor=''
          >
            You have up to {MAXINSTRUCTIONCHARS} characters to use. You have
            used {instructuctionChars} characters.
          </label>
        ) : (
          <label
            className='block mt-1 text-sm font-medium text-white'
            htmlFor=''
          >
            {' '}
            You are over the character limit. You have used{' '}
            {instructuctionChars} characters.
          </label>
        )}
      </div>
      <button
        className='xs:flex-shrink-0 group relative flex w-full h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
        type='submit'
      >
        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
        <span>Submit</span>
      </button>
    </form>
  );
};

export default CreateChatbot;
