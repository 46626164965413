import React, { useState, useEffect } from 'react';
import { useUser } from '../providers/UserProvider';
import axios from 'axios';
import { useChatbotStore } from '../store';
import UploadInput from './Upload';
import ColorPickerInput from './ColorPickerInput';
axios.defaults.withCredentials = true;
const ChatbotSettings = () => {
  const {
    result: { userId },
  } = useUser();
  const assistant_id = useChatbotStore((state) => state.current_assistant);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [headercolor, setHeaderColor] = useState('#000000');
  const [S3LogoUrl, setS3LogoUrl] = useState('');
  const [S3LauncherIcon, setS3LauncherIcon] = useState('');
  const [error, setError] = useState(null);
  const UPLOAD_URL = `${process.env.REACT_APP_URL}/api/settings/${userId}/upload`;
  const SAVE_URL = `${process.env.REACT_APP_URL}/api/settings/${assistant_id}/save`;
  const LOAD_URL = `${process.env.REACT_APP_URL}/api/settings/${assistant_id}/load`;
  const [widgetTitle, setWidgetTitle] = useState('Chat Widget');
  const [headerTextColor, setHeaderTextColor] = useState('#FFFFFF');
  const [isRounded, setIsRounded] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        setError(null);
        const authToken = localStorage.getItem('psg_auth_token');
        const response = await axios.get(LOAD_URL, {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        });
        setBackgroundColor(response.data.backgroundColor);
        setHeaderColor(response.data.headerColor);
        setS3LogoUrl(
          response.data.s3LogoUrl ||
            `${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`
        );
        setS3LauncherIcon(
          response.data.s3LauncherIcon ||
            `${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`
        );
        setWidgetTitle(response.data.widgetTitle || 'Chat Widget');
        setHeaderTextColor(response.data.headerTextColor || '#FFFFFF');
        setIsRounded(response.data.isRounded !== undefined ? response.data.isRounded : false);
      } catch (error) {
        console.error('Error loading settings', error);
        setBackgroundColor('#000000');
        setHeaderColor('#000000');
        setS3LogoUrl(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
        setS3LauncherIcon(`${process.env.REACT_APP_AWS_DOMAIN}/choony.svg`);
        setError('Error loading settings');
      }
    };
    loadSettings();
  }, [assistant_id]);

  const saveSettings = async (e) => {
    e.preventDefault();
    setError(null);
    const remove = document.getElementById('chat-widget');
    if (remove) remove.remove();
    
    try {
      const currentSettingsResponse = await axios.get(LOAD_URL, {
        withCredentials: true,
      });
      
      const currentSettings = currentSettingsResponse.data || {};
      
      const updatedSettings = {
        ...currentSettings,
        backgroundColor,
        headerColor: headercolor,
        s3LogoUrl: S3LogoUrl,
        s3LauncherIcon: S3LauncherIcon,
        widgetTitle,
        headerTextColor,
        isRounded,
      };
      
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        SAVE_URL,
        updatedSettings,
        {
          headers: {
            Cookie: `psg_auth_token=${authToken}`,
          },
          withCredentials: true,
        }
      );
      location.reload();
    } catch (error) {
      console.error('Error saving settings', error);
      setError('Error saving settings');
    }
  };

  const uploadFile = async (e, setterFunc) => {
    console.log(e.target.files[0]);
    console.log(e.target.files[0].type);
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(UPLOAD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Cookie: `psg_auth_token=${authToken}`,
        },
        withCredentials: true,
      });
      e.target.value = null;
      setterFunc(response.data.url);
    } catch (error) {
      console.error('Error uploading logo', error);
      setError('Error uploading logo');
    }
  };

  return (
    <>
      <h1 className='text-2xl font-bold text-white  mb-4'>Chatbot Settings</h1>
      <div className='mb-4 w-full'>
        <form className='w-full '>
          <div className='lg:flex lg:flex-row lg:gap-4'>
          <div className='mb-4 flex-1'>
            <ColorPickerInput
              label="Header Color"
              color={headercolor}
              onChange={setHeaderColor}
            />
            
            <ColorPickerInput
              label="Background Color"
              color={backgroundColor}
              onChange={setBackgroundColor}
            />
            
            <ColorPickerInput
              label="Header Text Color"
              color={headerTextColor}
              onChange={setHeaderTextColor}
            />

            <label className='block mb-1 text-sm font-medium text-white' htmlFor='widgetTitle'>
              Widget Title
            </label>
            <input
              className='py-2 px-4 h-11 w-full mb-6 text-white placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
              type='text'
              id='widgetTitle'
              value={widgetTitle}
              onChange={(e) => setWidgetTitle(e.target.value)}
              placeholder='Widget Title'
            />

            <label className='flex items-center mb-4'>
              <input
                type='checkbox'
                checked={isRounded}
                onChange={(e) => setIsRounded(e.target.checked)}
                className='form-checkbox h-5 w-5 text-yellowGreen-600 rounded border-gray-700 bg-gray-800'
              />
              <span className='ml-2 text-white'>Enable Rounded Corners</span>
            </label>
          </div>
          <div className='mb-4 flex-1'>
            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Logo (png and svg only max 500kb)
            </label>
            <div className='flex items-center justify-center w-full'>
              <img src={S3LogoUrl || ''} alt='logo' className='w-10 h-10' />
              <UploadInput
                allowedFiles={['image/png', 'image/svg+xml']}
                onChange={uploadFile}
                setter={setS3LogoUrl}
              />
            </div>

            <label
              className='block mb-1 text-sm font-medium text-white'
              htmlFor=''
            >
              Launcher Icon (png and svg only max 500kb)
            </label>
            <div className='flex items-center justify-center w-full'>
              <img
                src={S3LauncherIcon || ''}
                alt='launcher icon'
                className='w-10 h-10'
              />
              <UploadInput
                allowedFiles={['image/png', 'image/svg+xml']}
                onChange={uploadFile}
                setter={setS3LauncherIcon}
              />
            </div>
          </div>
          </div>
          <div className='flex flex-col'>
            <p className='text-red-500 text-xs italic'>{error}</p>
            <button
              className='bg-yellowGreen-500 hover:bg-yellowGreen-700 text-white font-bold py-2 px-4 rounded-md max-w-sm'
              type='button'
              onClick={saveSettings}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChatbotSettings;
