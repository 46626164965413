import React, { useState } from 'react';
import axios from 'axios';
import useScrollToTop from '../hooks/useScrollToTop';

const EmailSupportForm = ({ title, subjects, prefix }) => {
  useScrollToTop();
  const uniqueId = (prefix = 'id-') =>
    prefix + Math.random().toString(16).slice(-4);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    body: '',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/support`,
        { ...formData, subject: uniqueId() + formData.subject }
      );
      setMessage('Form submitted successfully');
      setFormData({
        name: '',
        phone: '',
        email: '',
        subject: '',
        body: '',
      });
      // Handle successful form submission
    } catch (error) {
      setError('Failed to submit form');
      // Handle form submission error
    }
  };

  return (
    <section tabIndex={0} className='py-12 bg-gray-900'>
      <div className='container px-4 mx-auto'>
        <div className='w-full  px-4 mb-8 lg:mb-0'>
          <h1 className='text-4xl font-bold text-white mb-12'>{title}</h1>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                className='block mb-1 text-sm font-medium text-white'
                htmlFor='name'
              >
                Name
              </label>
              <input
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Your name'
                tabIndex={0}
                required
              />
            </div>
            <div className='mb-4'>
              <label
                className='block mb-1 text-sm font-medium text-white'
                htmlFor='phone'
              >
                Phone
              </label>
              <input
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='tel'
                name='phone'
                value={formData.phone}
                onChange={handleChange}
                placeholder='Your phone number'
                tabIndex={0}
                required
              />
            </div>
            <div className='mb-4'>
              <label
                className='block mb-1 text-sm font-medium text-white'
                htmlFor='email'
              >
                Email
              </label>
              <input
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='Your email'
                tabIndex={0}
                required
              />
            </div>
            <div className='mb-4'>
              <label
                className='block mb-1 text-sm font-medium text-white'
                htmlFor='subject'
              >
                Subject
              </label>
              <select
                className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                name='subject'
                value={formData.subject}
                onChange={handleChange}
                tabIndex={0}
                required
              >
                <option value='' disabled>
                  Select a subject
                </option>
                {subjects.map((subject, index) => (
                  <option key={index} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </div>
            <div className='mb-8'>
              <label
                className='block mb-1 text-sm font-medium text-white'
                htmlFor='body'
              >
                Message
              </label>
              <textarea
                className='block py-2 px-4 w-full mb-1 h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                name='body'
                placeholder='Enter your message'
                value={formData.body}
                onChange={handleChange}
                tabIndex={0}
                required
              />
            </div>
            <div className='mb-4'>
              <span className='text-white'>{message}</span>
              <span className='text-red-500'>{error}</span>
            </div>
            <div className='mb-4 flex gap-2'>
              <button
                type='reset'
                className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
              >
                <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                <span>Reset</span>
              </button>
              <button
                type='submit'
                className='xs:flex-shrink-0 group relative flex-1 w-1/2 h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
              >
                <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EmailSupportForm;
