import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = ({ text, link = null, action = null }) => {
  return (
    <>
      {link ? (
        <div className='group relative mb-4 xs:mb-0 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 w-full'>
          <Link to={link}>
            <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
            <span>{text}</span>
          </Link>
        </div>
      ) : (
        <button
          className='group relative mb-4 xs:mb-0 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 w-full'
          onClick={action}
        >
          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
          <span>{text}</span>
        </button>
      )}
    </>
  );
};

export default LinkButton;
