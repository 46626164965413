import React from 'react';
import { useOpenAi } from '../providers/OpenAiProvider';
import { useUser } from '../providers/UserProvider';
import LinkButton from './LinkButton';

const File = ({ assistant_id, file, onUpload, onDelete }) => {
  // Simple display component with callbacks to parent
  const displayName = file?.filename ? 
    file.filename
      .replace(`${assistant_id}_`, '')
      .replace('__', ' ')
      .split('_')
      .join(' ') : '';

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    const formData = new FormData();
    formData.append('file', file);
    await onUpload(formData);
    e.target.value = null;
  };

  const handleDelete = async () => {
    const confirmMessage = `Are you sure you want to delete the file "${displayName}"? This action cannot be undone.`;
    if (window.confirm(confirmMessage)) {
      await onDelete(file.id);
    }
  };

  return (
    <div className='flex items-center justify-between mb-2'>
      {file ? (
        <>
          <span className='bg-gray-900 flex-1 text-white p-2 rounded-lg mr-2 my-2 break-all'>
            {displayName}
          </span>
          <LinkButton 
            action={handleDelete} 
            text={'Delete'} 
          />
        </>
      ) : (
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='file'
          onChange={handleUpload}
          accept=".txt,.pdf,.doc,.docx"
        />
      )}
    </div>
  );
};

const EditChatbot = ({ assistant }) => {
  const { files, getFiles, uploadFile, deleteFile } = useOpenAi();
  const {
    result: { userId },
  } = useUser();

  React.useEffect(() => {
    if (!userId || !assistant?.assistant_id) return;
    
    // Initial load of files
    getFiles(userId, assistant.assistant_id);
  }, [assistant?.assistant_id, userId]);

  if (!assistant) {
    return <div className="text-white">No assistant selected</div>;
  }

  const handleUpload = async (formData) => {
    try {
      await uploadFile(userId, assistant.assistant_id, formData);
      await getFiles(userId, assistant.assistant_id);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = async (fileId) => {
    try {
      await deleteFile(userId, assistant.assistant_id, fileId);
      await getFiles(userId, assistant.assistant_id);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <div>
      <p className='text-white text-lg font-bold'>Assistant Files</p>
      <File 
        assistant_id={assistant.assistant_id} 
        onUpload={handleUpload}
        onDelete={handleDelete}
      />
      {Array.isArray(files) && files.map((file) => (
        <File
          key={file.id || file.vector_store_id}
          file={file}
          assistant_id={assistant.assistant_id}
          onUpload={handleUpload}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default EditChatbot;
