// OpenAiContext.js

import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

/**app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files', view_func=list_files, methods=['GET'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files', view_func=create_assistant_file, methods=['POST'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files/upload', view_func=upload_assistant_file, methods=['POST'])
app.add_url_rule('/api/openai/assistants/<user_id>/<assistant_id>/files/<file_id>', view_func=delete_assistant_file, methods=['DELETE'])
app.add_url_rule('/api/settings/<assistant_id>/sites', view_func=load_allowed_sites, methods=['GET'])
app.add_url_rule('/api/settings/<assistant_id>/sites', view_func=save_allowed_sites, methods=['POST'])
*/
axios.defaults.withCredentials = true;
const OpenAiContext = createContext();
const AssistantModels = [
  {
    id: 'gpt-4o',
    description: 'GPT-4o: OpenAI\'s newest flagship model offering best-in-class speed, intelligence, and multimodal capabilities.'
  },
  {
    id: 'gpt-4-turbo',
    description: 'GPT-4 Turbo: Fast and powerful GPT-4 variant, optimized for responsive, detailed chatbot interactions.'
  },
  {
    id: 'gpt-4-0613',
    description: 'GPT-4-0613: Stable, reliable GPT-4 model with consistent performance for complex chatbot scenarios.'
  },
  {
    id: 'gpt-4',
    description: 'GPT-4: Original advanced GPT-4 model providing deep understanding and high-quality chatbot responses.'
  },
  {
    id: 'gpt-3.5-turbo',
    description: 'GPT-3.5 Turbo: Efficient and cost-effective, ideal for quick, everyday chatbot interactions.'
  },
  {
    id: 'gpt-3.5-turbo-16k',
    description: 'GPT-3.5 Turbo 16k: Extended context GPT-3.5 variant suitable for handling longer chatbot conversations and tasks.'
  },
  {
    id: 'gpt-3.5-turbo-1106',
    description: 'GPT-3.5 Turbo 1106: Updated GPT-3.5 model with improved accuracy and conversational responsiveness.'
  },
  {
    id: 'gpt-3.5-turbo-instruct',
    description: 'GPT-3.5 Turbo Instruct: Specialized GPT-3.5 instruct model optimized for following explicit chatbot instructions.'
  }
]
;
const MAX_CHATBOTS = 5;

// Create a separate interceptor setup
const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 403) {
        // Clear auth token
        localStorage.removeItem('psg_auth_token');
        // Redirect using window.location instead of navigate
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );
};

// Call it once when the app starts
setupAxiosInterceptors();

export const OpenAiProvider = ({ children }) => {
  const [models, setModels] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [allowedSites, setAllowedSites] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const modelUrl = `${process.env.REACT_APP_URL}/api/openai/models/`;
  const chatbotUrl = `${process.env.REACT_APP_URL}/api/openai/assistant/`;
  const chatbotsUrl = `${process.env.REACT_APP_URL}/api/openai/assistants/`;
  const settingsUrl = `${process.env.REACT_APP_URL}/api/settings/`;

  const getFiles = async (userId, assistantId) => {
    try {
      setError(null);
      setFiles([]);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files`,
        {
          headers: {},
        }
      );
      console.log({ files: response.data });
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files', error);
      setError('Error fetching files');
    }
  };

  const createFile = async (userId, assistantId, data) => {
    try {
      setError(null);
      //console.log({ data, chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      //console.log({ file: response.data });
    } catch (error) {
      console.error('Error creating file', error);
      setError('Error creating file');
    }
  };

  const uploadFile = async (userId, assistantId, formData) => {
    try {
      console.log(`Uploading file for assistant ${assistantId}`);
      
      // Match the exact route we defined above
      const url = `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files/upload`;
      console.log('Upload URL:', url);
      
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: formData,
        // Don't set Content-Type for multipart/form-data
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Upload failed: ${errorText}`);
        throw new Error(`Failed to upload file: ${errorText}`);
      }
      
      const result = await response.json();
      console.log('File uploaded successfully:', result);
      return result;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const deleteFile = async (userId, assistantId, fileId) => {
    try {
      console.log(`Deleting file ${fileId} for assistant ${assistantId}`);
      
      // Match the exact route we defined above
      const url = `${process.env.REACT_APP_URL}/api/openai/assistants/${userId}/${assistantId}/files/${fileId}`;
      console.log('Delete URL:', url);
      
      // First, make sure we update the files list after deletion
      const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Delete failed: ${errorText}`);
        throw new Error(`Failed to delete file: ${errorText}`);
      }
      
      const result = await response.json();
      console.log('File deleted successfully:', result);
      
      // Refresh the files list after deletion
      await getFiles(userId, assistantId);
      
      return result;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };

  // Function to get the models
  const getModels = async (userId) => {
    try {
      setError(null);
      const authtoken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(modelUrl + userId, {
        headers: {},
      });
      console.log('Raw API Response:', response.data);
      console.log('Available models from API:', response.data.map(m => m.id));
      console.log('Allowed model IDs:', AssistantModels.map(m => m.id));
      
      if (response.data.length === 0) {
        setError('No models found. INVALID API KEY');
      } else {
        const allowedModels = AssistantModels.filter((model) => {
          const isAllowed = response.data.some((am) => am.id === model.id);
          console.log(`Checking model ${model.id}: ${isAllowed ? 'allowed' : 'not allowed'}`);
          return isAllowed;
        });
        //console.log('Final filtered models:', allowedModels.map(m => { return { id: m.id, description: m.description }}));
        setModels(allowedModels);
      }
    } catch (error) {
      console.error('Error fetching models', error);
      setError('Error fetching models');
    }
  };

  const createChatbot = async (userId, data) => {
    if (chatbots.length >= MAX_CHATBOTS) {
      setError('You have reached the maximum number of chatbots');
      return;
    }
    try {
      setError(null);
      //console.log({ data, chatbotUrl, userId });
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(chatbotUrl + userId, data, {
        headers: {},
      });
      //console.log({ chatbot: response.data, chatbotUrl });
    } catch (error) {
      console.error('Error creating chatbot', error);
      setError('Error creating chatbot');
    }
  };

  // Define other methods similarly
  const getChatbots = async (userId) => {
    try {
      setError(null);
      setIsLoading(true);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(chatbotsUrl + userId, {
        headers: {},
      });
      setChatbots(response.data);
    } catch (error) {
      console.error('Error fetching assistants', error);
      setError('Error fetching assistants');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChatbot = async (user_id, assistant_id) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.delete(
        chatbotUrl + user_id + '/' + assistant_id,
        {
          headers: {},
        }
      );
      //console.log({ assistants: response.data, chatbotsUrl });
      //setChatbots(response.data);
    } catch (error) {
      console.error('Error deleting assistant', error);
      setError('Error deleting assistant');
    }
  };

  const updateAllowedSites = (newSite, assistant_id) => {
    // Create a new Set based on the current allowedSites
    const updatedSites = new Set(allowedSites);

    // Add the new site
    updatedSites.add(newSite);

    // Update the state with the new Set

    setAllowedSites(updatedSites);

    // Save the new allowed sites
    saveAllowedSites(assistant_id, updatedSites);
  };

  const removeAllowedSite = (site, assistant_id) => {
    // Create a new Set based on the current allowedSites
    const updatedSites = new Set(allowedSites);

    // Add the new site
    updatedSites.delete(site);

    // Update the state with the new Set
    setAllowedSites(updatedSites);

    // Save the new allowed sites
    saveAllowedSites(assistant_id, updatedSites);
  };

  const loadAllowedSites = async (assistant_id) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.get(settingsUrl + assistant_id + '/sites', {
        headers: {},
      });
      //console.log({ allowedSites: response.data, settingsUrl });
      const allowed = new Set(response?.data?.sites.split(',') || []);
      setAllowedSites(allowed);
    } catch (error) {
      console.error('Error fetching allowed sites', error);
      setError('Error fetching allowed sites');
    }
  };

  const saveAllowedSites = async (assistant_id, sites) => {
    try {
      setError(null);
      const authToken = localStorage.getItem('psg_auth_token');
      const response = await axios.post(
        settingsUrl + assistant_id + '/sites',
        {
          allowed_sites: Array.from(sites).join(','),
        },
        { headers: {} }
      );
      //console.log({ allowedSites: response.data, settingsUrl });
    } catch (error) {
      console.error('Error saving allowed sites', error);
      setError('Error saving allowed sites');
    }
  };

  return (
    <OpenAiContext.Provider
      value={{
        error,
        models,
        getModels,
        chatbots,
        getChatbots,
        isLoading,
        createChatbot,
        getFiles,
        files,
        //createFile,
        uploadFile,
        deleteFile,
        deleteChatbot,
        allowedSites,
        updateAllowedSites,
        removeAllowedSite,
        loadAllowedSites,
        saveAllowedSites,
      }}
    >
      {children}
    </OpenAiContext.Provider>
  );
};

export const useOpenAi = () => {
  const context = useContext(OpenAiContext);
  if (!context) {
    throw new Error(
      'You are trying to use context data outside of its provider'
    );
  }
  return context;
};
