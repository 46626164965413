import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useScrollToTop from '../hooks/useScrollToTop';

const ToolManager = () => {
  useScrollToTop();
  const [tools, setTools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsavedTool, setHasUnsavedTool] = useState(false);
  const apiUrl = '/mock-api/tools'; // Replace with your API endpoint

  const dataTypes = [
    'string',
    'integer',
    'boolean',
    'object',
    'array',
    'number',
  ];

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await axios.get(apiUrl);
        setTools(response.data || []);
      } catch (error) {
        console.error('Error fetching tools:', error);
      } finally {
        setIsLoading(false);
      }
    };
    //fetchTools();
  }, []);

  const isValidHttpsUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === 'https:';
    } catch (error) {
      return false;
    }
  };

  const handleToolChange = (index, field, value) => {
    const updatedTools = [...tools];
    updatedTools[index] = {
      ...updatedTools[index],
      function: {
        ...updatedTools[index].function,
        [field]: value,
      },
    };
    setTools(updatedTools);
  };

  const handlePropertyChange = (toolIndex, propertyKey, field, value) => {
    const updatedTools = [...tools];
    updatedTools[toolIndex].function.parameters.properties[propertyKey] = {
      ...updatedTools[toolIndex].function.parameters.properties[propertyKey],
      [field]: value,
    };
    setTools(updatedTools);
  };

  const toggleRequiredProperty = (toolIndex, propertyKey) => {
    const updatedTools = [...tools];
    const requiredList = updatedTools[toolIndex].function.parameters.required;
    updatedTools[toolIndex].function.parameters.required =
      requiredList.includes(propertyKey)
        ? requiredList.filter((key) => key !== propertyKey)
        : [...requiredList, propertyKey];
    setTools(updatedTools);
  };

  const deleteProperty = (toolIndex, propertyKey) => {
    const updatedTools = [...tools];
    const { [propertyKey]: _, ...remainingProperties } =
      updatedTools[toolIndex].function.parameters.properties;
    updatedTools[toolIndex].function.parameters.properties =
      remainingProperties;
    updatedTools[toolIndex].function.parameters.required = updatedTools[
      toolIndex
    ].function.parameters.required.filter((key) => key !== propertyKey);
    setTools(updatedTools);
  };

  const addEnumValue = (toolIndex, propertyKey) => {
    const enumValue = prompt('Enter enum value:');
    if (enumValue) {
      const updatedTools = [...tools];
      const property =
        updatedTools[toolIndex].function.parameters.properties[propertyKey];
      const updatedEnum = [...(property.enum || []), enumValue];
      property.enum = updatedEnum;
      setTools(updatedTools);
    }
  };

  const removeEnumValue = (toolIndex, propertyKey, enumValue) => {
    const updatedTools = [...tools];
    const property =
      updatedTools[toolIndex].function.parameters.properties[propertyKey];
    property.enum = property.enum.filter((value) => value !== enumValue);
    setTools(updatedTools);
  };

  const addPropertyInline = (toolIndex, newPropertyName) => {
    if (newPropertyName.trim() !== '') {
      const updatedTools = [...tools];
      const properties = updatedTools[toolIndex].function.parameters.properties;
      if (!properties[newPropertyName]) {
        properties[newPropertyName] = {
          type: 'string',
          description: '',
          enum: undefined,
        };
        setTools(updatedTools);
      } else {
        alert('Property already exists!');
      }
    }
  };

  const addNewTool = () => {
    const newTool = {
      id: `new-${Date.now()}`, // Temporary ID
      type: 'function',
      function: {
        name: '',
        description: '',
        webhook_url: '',
        parameters: {
          type: 'object',
          properties: {},
          required: [],
        },
      },
    };
    setTools([...tools, newTool]);
    setHasUnsavedTool(true);
  };

  const removeUnsavedTool = (index) => {
    const updatedTools = tools.filter((_, idx) => idx !== index);
    setTools(updatedTools);
    setHasUnsavedTool(false);
  };

  const handleSaveTool = async (index) => {
    const tool = tools[index];
    if (!tool.function.name.trim() || !tool.function.description.trim()) {
      alert('Function name and description are required!');
      return;
    }
    if (!isValidHttpsUrl(tool.function.webhook_url)) {
      alert('Webhook URL must be a valid HTTPS URL!');
      return;
    }

    try {
      if (tool.id.startsWith('new')) {
        // await axios.post(apiUrl, tool); // Replace with your POST API endpoint for new tools
        setHasUnsavedTool(false);
      } else {
        // await axios.put(`${apiUrl}/${tool.id}`, tool); // Replace with your PUT API endpoint for existing tools
      }
      alert('Tool saved successfully!');
    } catch (error) {
      console.error('Error saving tool:', error);
      alert('Error saving tool!');
    }
  };

  const renderProperties = (toolIndex, properties) => (
    <div>
      {Object.entries(properties).map(([key, prop]) => (
        <div key={key} className='mb-6 p-4 bg-gray-800 rounded-lg'>
          <h6 className='text-white font-bold'>{key}</h6>
          <div className='mt-2'>
            <label className='block mb-1 text-sm text-white'>Type</label>
            <select
              className='w-full py-2 px-4 bg-gray-900 text-gray-300 border border-gray-700 rounded-lg'
              value={prop.type}
              onChange={(e) =>
                handlePropertyChange(toolIndex, key, 'type', e.target.value)
              }
            >
              {dataTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className='mt-2'>
            <label className='block mb-1 text-sm text-white'>Description</label>
            <input
              type='text'
              className='w-full py-2 px-4 bg-gray-900 text-gray-300 border border-gray-700 rounded-lg'
              value={prop.description}
              onChange={(e) =>
                handlePropertyChange(
                  toolIndex,
                  key,
                  'description',
                  e.target.value
                )
              }
            />
          </div>
          {prop.type === 'string' && (
            <div className='mt-2'>
              <h6 className='text-sm text-white font-medium'>Enum Values</h6>
              <div className='flex flex-wrap gap-2 mt-2'>
                {prop.enum?.map((enumValue, index) => (
                  <span
                    key={index}
                    className='px-3 py-1 bg-gray-700 text-white rounded-full flex items-center'
                  >
                    {enumValue}
                    <button
                      className='ml-2 text-red-500'
                      onClick={() => removeEnumValue(toolIndex, key, enumValue)}
                    >
                      x
                    </button>
                  </span>
                ))}
              </div>
              <button
                className='mt-2 px-4 py-2 text-gray-900 bg-yellowGreen-600 rounded-lg'
                onClick={() => addEnumValue(toolIndex, key)}
              >
                Add Enum
              </button>
            </div>
          )}
          <div className='mt-4 flex justify-between'>
            <button
              className='px-4 py-2 text-gray-900 bg-yellowGreen-600 rounded-lg'
              onClick={() => toggleRequiredProperty(toolIndex, key)}
            >
              {tools[toolIndex].function.parameters.required.includes(key)
                ? 'Unset Required'
                : 'Set Required'}
            </button>
            <button
              className='px-4 py-2 text-gray-900 bg-red-600 rounded-lg'
              onClick={() => deleteProperty(toolIndex, key)}
            >
              Delete Property
            </button>
          </div>
        </div>
      ))}
      <div className='flex items-center mt-4'>
        <input
          type='text'
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          placeholder='Enter property name'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addPropertyInline(toolIndex, e.target.value);
              e.target.value = '';
              e.preventDefault();
            }
          }}
        />
        <span className='text-white ml-2'>Press Enter to Add</span>
      </div>
    </div>
  );

  const renderToolEditor = (tool, index) => (
    <div
      key={index}
      className='relative p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-sm mb-6'
    >
      <h5 className='text-xl font-medium text-white mb-4'>
        {tool.id.startsWith('new') ? 'Add New Tool' : `Edit Tool #${index + 1}`}
      </h5>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white'>
          Function Name
        </label>
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='text'
          value={tool.function.name}
          onChange={(e) => handleToolChange(index, 'name', e.target.value)}
          placeholder='Enter function name'
        />
      </div>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white'>
          Description
        </label>
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='text'
          value={tool.function.description}
          onChange={(e) =>
            handleToolChange(index, 'description', e.target.value)
          }
          placeholder='Enter description'
        />
      </div>
      <div className='mb-4'>
        <label className='block mb-1 text-sm font-medium text-white'>
          Webhook URL
        </label>
        <input
          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
          type='text'
          value={tool.function.webhook_url}
          onChange={(e) =>
            handleToolChange(index, 'webhook_url', e.target.value)
          }
          placeholder='Enter webhook URL (must be HTTPS)'
        />
      </div>
      <div className='mb-4'>
        <h5 className='text-lg font-medium text-white mb-4'>Properties</h5>
        {renderProperties(index, tool.function.parameters.properties)}
      </div>
      <div className='mt-4 flex justify-between'>
        <button
          type='button'
          className='px-4 py-2 text-gray-900 bg-yellowGreen-600 rounded-lg'
          onClick={() => handleSaveTool(index)}
        >
          Save Tool
        </button>
        {tool.id.startsWith('new') && (
          <button
            type='button'
            className='px-4 py-2 text-gray-900 bg-red-600 rounded-lg'
            onClick={() => removeUnsavedTool(index)}
          >
            Remove Tool
          </button>
        )}
      </div>
      <pre className='mt-4 p-4 bg-gray-800 text-white rounded-lg overflow-auto'>
        {JSON.stringify(tool, null, 2)}
      </pre>
    </div>
  );

  return (
    <section className='py-12 bg-gray-900 min-h-screen'>
      <div className='container px-4 mx-auto'>
        <div className='w-full px-4 mb-8 lg:mb-0'>
          <h1 className='text-4xl font-bold text-white mb-12'>Tool Manager</h1>
          <button
            className={`mb-4 px-4 py-2 text-gray-900 bg-yellowGreen-600 rounded-lg ${
              hasUnsavedTool ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={addNewTool}
            disabled={hasUnsavedTool}
          >
            Add New Tool
          </button>
          {isLoading ? (
            <p className='text-white'>Loading tools...</p>
          ) : tools.length === 0 ? (
            <p className='text-white'>
              No tools found. Click "Add New Tool" to create one.
            </p>
          ) : (
            tools.map((tool, index) => renderToolEditor(tool, index))
          )}
        </div>
      </div>
    </section>
  );
};

export default ToolManager;
